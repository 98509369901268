import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

const HelloBar = () => {
    const data = useStaticQuery(graphql`
        query {
            datoCmsTranslation(locale: {eq: "it"}) {
                helloBarNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
  `)

    return (
        <div className="bg-zone-accent text-center py-3 px-4 text-sm font-signika">
            <div className="inner-hello-bar" dangerouslySetInnerHTML={{ __html: data.datoCmsTranslation.helloBarNode.childMarkdownRemark.html }} />
        </div>
    )
}

export default HelloBar
